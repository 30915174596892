import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Geolocation } from '@capacitor/geolocation';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';
import { useDevice } from './useDevice';

export interface Coordinates {
  lat: number;
  lng: number;
}

const DENVER_COORDS: Coordinates = {
  lat: 39.7392358,
  lng: -104.990251,
};

const CHICAGO_COORDS: Coordinates = {
  lat: 41.8781136,
  lng: -87.6297982,
};

const PALM_SPRINGS_COORDS: Coordinates = {
  lat: 33.8244857,
  lng: -116.5371162,
};

const BOSTON_COORDS: Coordinates = {
  lat: 42.3600825,
  lng: -71.0588801,
};

const checkLocationPermissionsWeb = async (): Promise<boolean> =>
  new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      () => resolve(true),
      (positionError) => {
        if (positionError.code === positionError.PERMISSION_DENIED) {
          resolve(false);
        }

        console.log(positionError);

        resolve(true);
      },
    );
  });

const fetchLocationWeb = (): Promise<Coordinates> =>
  new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (positionError) => {
        if (positionError.code === positionError.PERMISSION_DENIED) {
          console.warn('User denied geolocation permissions');
        }
        console.log(positionError);

        resolve(DENVER_COORDS);
      },
    );
  });

const fetchLocationNative = async (): Promise<Coordinates> => {
  const { coords } = await Geolocation.getCurrentPosition();

  return {
    lat: coords.latitude,
    lng: coords.longitude,
  };
};

const checkLocationPermissionsNative = async (): Promise<boolean> => {
  const { location } = await Geolocation.checkPermissions();

  return location !== 'denied';
};

const getCurrentPosition = async () => {
  try {
    const { coords } = await Geolocation.getCurrentPosition();

    return { coords };
  } catch (e: any) {
    const { code } = e ?? {};

    if (code === 1) {
      const storedCityOverride = localStorage.getItem('currentCityOverride');

      if (storedCityOverride) {
        const parsedStoredCityOverride: CurrentCity = JSON.parse(storedCityOverride);

        if (parsedStoredCityOverride?.coordinates) {
          return {
            coords: {
              latitude: parsedStoredCityOverride.coordinates.lat,
              longitude: parsedStoredCityOverride.coordinates.lng,
            },
          };
        }
      }
    }

    console.log(e);
  }
};

const LOCATION_ENABLED = false;

export const useCurrentUserLocation = () => {
  const { userIsLoggedIn, isLocationPermissionGateDisabled, currentCityOverride } = useAppContext();

  const fetchCurrentLocation = async (): Promise<Coordinates> => {
    if (currentCityOverride) {
      return {
        lat: currentCityOverride.coordinates.lat,
        lng: currentCityOverride.coordinates.lng,
      };
    }

    const { coords } = await getCurrentPosition();

    const storedCityOverride = localStorage.getItem('currentCityOverride');

    if (!coords && storedCityOverride) {
      const parsedStoredCityOverride: CurrentCity = JSON.parse(storedCityOverride);

      if (parsedStoredCityOverride?.coordinates) {
        return {
          lat: parsedStoredCityOverride.coordinates.lat,
          lng: parsedStoredCityOverride.coordinates.lng,
        };
      }
    }

    return {
      lat: coords.latitude,
      lng: coords.longitude,
    };
  };

  const {
    isFetched: currentLocationIsReady,
    data: currentLocation,
    refetch,
  } = useQuery({
    queryKey: ['currentLocation', currentCityOverride?.coordinates],
    queryFn: fetchCurrentLocation,
    enabled: userIsLoggedIn && !isLocationPermissionGateDisabled,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const checkCurrentLocationPermissions = async (): Promise<boolean> => {
    try {
      const { coords } = await getCurrentPosition();

      if (coords?.latitude && coords?.longitude) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }

    const { location } = await Geolocation.checkPermissions();

    return location === 'granted';
  };

  return {
    currentLocationIsReady,
    currentLocation,
    refetchCurrentLocation: refetch,
    checkCurrentLocationPermissions,
  };
};

export interface CurrentCity {
  _id: string;
  name: string;
  slug?: string;
  eventsAreEnabled: boolean;
  coordinates: Coordinates;
}

export const useCurrentUserCity = () => {
  const { currentLocationIsReady, currentLocation } = useCurrentUserLocation();
  const { lat, lng } = currentLocation ?? {};

  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserCity.queryKey(currentLocation),
    queryFn: () =>
      API.get<{
        city: CurrentCity;
        primaryCity: CurrentCity;
        cities: CurrentCity[];
        multiCityEnabled?: boolean;
      }>(`/v1/city/me?lat=${lat}&lng=${lng}`),
    enabled: Boolean(currentLocationIsReady && lat && lng),
  });

  return {
    currentCityIsReady: isFetched,
    currentCity: data?.city,
    primaryCity: data?.primaryCity,
    cities: data?.cities,
    multiCityEnabled: data?.multiCityEnabled,
    refetchCity: refetch,
  };
};
useCurrentUserCity.queryKey = ({ lat, lng }: Coordinates = {} as Coordinates) => [
  'user',
  'city',
  lat,
  lng,
];

export const useCitiesQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCitiesQuery.queryKey(),
    queryFn: () => useCitiesQuery.queryFn(),
  });

  return {
    citiesAreReady: isFetched,
    cities: data?.cities,
    refetchCities: refetch,
  };
};

useCitiesQuery.queryKey = () => ['cities'];
useCitiesQuery.queryFn = () => API.get<{ cities: CurrentCity[] }>(`/v1/cities`);

export const useCityById = (cityId: string) => {
  const { citiesAreReady, cities } = useCitiesQuery();

  console.log(cities);

  const city = useMemo(() => cities?.find((city) => city._id === cityId), [cities, cityId]);

  return {
    cityIsReady: citiesAreReady,
    city,
  };
};
